<template>
  <div class="d-flex flex-column flex-md-row align-items-center gap-3 gap-md-0 justify-content-between">
    <div class="d-block">
      <div class="border-r-10 d-flex gap-4 justify-content-center justify-content-md-start align-items-center w-100">
        <div class="text-decoration-none lh-sm pointer" @click="goBack">
          <!-- :to="{ name: 'student-my-teachers' }" -->
          <i class="pi pi-arrow-left text-primary-1 fs-4 fw-bold pointer pt-2"></i>
        </div>
        <h2 class="text-primary-1 fw-bold first-letter-uppercase mb-0">
          schedule your class
        </h2>

      </div>
      <select-button class="select-buttons-custom w-100 mt-3" :class="currentOption === 'By time' ? 'by-time' : 'by-teacher'" @change="changeCurrentView" v-model="currentOption"
        :options="selectOptions" />
    </div>


  </div>
  <class-by-time-component v-if="currentOption === 'By time'" option="time" />
  <class-by-teacher-component v-if="currentOption === 'By teacher'" option="teacher" />
</template>

<script>
import CardSmallInfoComponent from "@/shared/components/Cards/CardSmallInfoComponent";
import ClassByTimeComponent from "@/shared/components/ScheduledClass/ClassByTimeComponent";
import ClassByTeacherComponent from "@/shared/components/ScheduledClass/ClassByTeacherComponent";
import useScheduledClass from "@/shared/composables/ScheduledClass/useScheduledClass";
import { useRoute } from 'vue-router';
import { onUpdated } from "vue";
export default {
  components: {
    CardSmallInfoComponent,
    ClassByTimeComponent,
    ClassByTeacherComponent,
  },
  setup() {
    const route = useRoute();
    const { selectOptions, currentOption, goBack, loadServices, changeCurrentView } = useScheduledClass();
    loadServices()
    if (route.params.isTeacher === 'true') {
      currentOption.value = 'By teacher'
    } else {
      currentOption.value = 'By time'
    }

    return { selectOptions, currentOption, goBack, changeCurrentView };
  },
};
</script>

<style lang="scss" scoped>
.select-buttons-custom {
  max-width: 250px;
}
</style>