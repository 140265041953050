<template>
  <div class="bg-white py-4 px-2 p-md-4 bl-shadow border-r-10 card-teacher">
    <header class="d-flex flex-column flex-sm-row gap-3 align-items-center">
      <div class="header-user- header-user-small w-100 text-center">
        <div class="position-relative text-center user-picture mb-2">
          <avatar
            :image="data.photo ?? '/assets/images/avatar.png'"
            shape="circle"
          />
        </div>
      </div>
    </header>
    <h1 class="fs-5 text-primary-1 text-center">
      {{ data.name }} {{ data.last_name }}
    </h1>
    <hr />
    <iframe
      v-if="getUrl(data.url_video).success"
      class="w-100 border-r-10 overflow-hidden"
      height="180px"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      lazyload
      :src="getUrl(data.url_video).url + '?autoplay=1&mute=1'"
    ></iframe>
    <div
      v-else
      class="
        w-100
        text-center
        d-flex
        align-items-center
        flex-column
        justify-content-center
        gap-3
        bg-primary-5
        border-r-10
        p-3
        mb-4
      "
      style="min-height: 10rem"
    >
      <span class="material-icons text-primary-1" style="font-size: 30px">
        priority_high
      </span>
      <p class="text-primary-1 fs-6 px-5 lh-sm">No video presentation found</p>
    </div>
    <!-- :style="isSelected ? 'background-color:#a5450e !important' : ''" -->
    <button
      @click="
        (e) => {
          changeIsSelected();
          selectTeacher(data);
        }
      "
      class="btn bl-shadow p-2 px-4 w-100"
      :class="
        isTeacher
          ? 'border-secondary-1 text-secondary-1 bg-hover-light-secondary-1'
          : 'bg-secondary-1 bg-hover-secondary-1 text-white'
      "
    >
      {{ isTeacher ? "Unselect teacher" : "Select teacher" }}
    </button>
  </div>
</template>

<script>
import { ref } from "vue";
import getYtUrlId from "@/shared/utils/getters/getYtUrlId";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    isTeacher: {
      type: Boolean,
      required: true,
    },
    selectTeacher: {
      type: Function,
    },
  },
  setup() {
    const changeIsSelected = () => {
     
    };
    const getUrl = (link = "") => {
      const res = {
        url: "",
        success: false,
      };
      const { status, id } = getYtUrlId(link);
      res.success = status === "success";

      if (res.success) {
        res.url = "https://www.youtube.com/embed/" + id;
      }

      return res;
    };
    return {
      changeIsSelected,
      getYtUrlId,
      getUrl,
    };
  },
};
</script>

<style>
</style>