import { computed, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import getTime from "@/shared/utils/dates/getTime";
const useScheduledClass = () => {
  const selectOptions = ["By time", "By teacher"];
  const currentOption = ref("By time");
  const currentStep = ref(0);
  const isConfirm = ref(false);
  const currentSteps = ref([]);
  const store = useStore();
  const router = useRouter();
  const seconds_time = ref(0);
  const filter_teacher = ref("");
  const seconds_start = ref(300); //300
  const loadServices = async () => {
    await store.dispatch("students/getTeachersAll");
  };
  const changeStep = async (step, option = "time", isback = false) => {
    currentStep.value = step;
    if (currentStep.value === 0) {
      store.commit("students/scheduledClass", { data: [] });
      store.commit("students/availablescheduleds", { data: [] });
      store.commit("students/teachersClass", { data: [] });
    }
    if (currentStep.value === 2) {
      //Mostrar el mensaje de 5 minutos cuando agenda
      // if (window.innerWidth >= 768) {
      //   seconds_time.value = 0;
      //   seconds_start.value = 300;
      //   let intervalId = setInterval(() => {
      //     if (currentStep.value === 2) {
      //       seconds_time.value++;
      //       seconds_start.value--;
      //       const { minutes, seconds_left } = getTime(seconds_start.value);
      //       const notification = {
      //         isOpen: true,
      //         status: "info",
      //         message: `Your classes are reserved for the next 0${minutes}:${
      //           seconds_left < 10 ? "0" + seconds_left : seconds_left
      //         }. Please confirm to book your classes.`,
      //       };
      //       store.commit("shared/uiNotificationStatus", notification);
      //       if (seconds_time.value === 300 || isConfirm.value) {
      //         clearInterval(intervalId);
      //         store.dispatch("shared/resetNotificationStatus");
      //         if (!isConfirm.value) {
      //           currentStep.value = 0;
      //           store.commit("students/scheduledClass", { data: [] });
      //         }
      //       }
      //     } else {
      //       clearInterval(intervalId);
      //       store.dispatch("shared/resetNotificationStatus");
      //     }
      //   }, 1000);
      // }
    }
    if (currentStep.value === 1 && option === "time" && !isback) {
      let { data } = store.getters["students/scheduledClass"];
      let payload = {
        dates: [],
      };

      data.forEach((time) => {
        payload.dates.push({ date: `${time.date} ${time.time_start}` });
      });

      await store.dispatch("students/availableTeachers", { payload });
      store.dispatch("shared/resetNotificationStatus");
    } else if (currentStep.value === 1 && option !== "time" && !isback) {
      let { all } = store.getters["students/getTeachers"];

      store.commit("students/availablescheduleds", { isLoading: true });
      await store.dispatch("students/availablescheduleds", { id: all.search });
      store.dispatch("shared/resetNotificationStatus");
    } else if (currentStep.value === 1 && option !== "time" && isback) {
      store.commit("students/scheduledClass", { data: [] });
    }
  };
  const changeSteps = (step, action) => {
    if ("add" === action) currentSteps.value.push(step);
    else if ("delete" === action)
      currentSteps.value = currentSteps.value.filter((i) => i !== step);
  };

  const toggleClassBookedModal = (isOpen) => {
    if (isOpen) {
      isConfirm.value = true;
    }
    store.commit("shared/setClassBookedModal", { isOpen });
  };

  const showClassBookedModal = computed(
    () => store.getters["shared/getClassBookedModal"].isOpen
  );

  const goBack = () => {
    router.back();
  };

  const teachersAll = computed(() => {
    return store.getters["students/getTeachers"];
  });

  const searchTeachers = async () => {
    store.commit("students/setTeachersAll", {
      isLoading: true,
      filter: true,
      search: filter_teacher.value.toLowerCase(),
    });
    await store.dispatch("students/getTeachersAll");
  };

  const changeCurrentView = () => {
    store.commit("students/scheduledClass", { data: [] });
  };

  return {
    changeCurrentView,
    searchTeachers,
    teachersAll,
    selectOptions,
    currentOption,
    currentStep,
    changeStep,
    currentSteps,
    changeSteps,
    showClassBookedModal,
    isConfirm,
    toggleClassBookedModal,
    goBack,
    loadServices,
    filter_teacher,
  };
};

export default useScheduledClass;
