<template>
  <div class="bg-white py-5 px-2 p-md-5 mt-4 border-r-10">
    <Accordion v-model:activeIndex="currentStep" class="accordeon-custom accordeon-scheduled">
      <AccordionTab :disabled="true">
        <template #header>
          <div class="d-flex gap-3 align-items-center">
            <div class="circle d-flex">
              {{ currentSteps.includes(1) ? "✓" : "1" }}
            </div>
            <p>Select time</p>
          </div>
        </template>
        <div class="content px-3 p-md-4" v-if="currentStep === 0">
          <p class="pt-3">
            All class slots are 30 minutes. <span class="fw-bold">To book longer sessions, select multiple time slots in
              a row.</span>
          </p>
          <p class="pb-3">
            Your current Time-Zone is {{ studentInformation.timezone_change }} ({{ timezone }} hrs). If you want to
            change it,
            <span class="text-primary-1 pointer fw-bold" @click="goToProfile">click here</span>
          </p>
          <div class="mb-3" v-if="!isLoadingTimes">
            <calendar-create-event-component :selectTime="selectTime" type="time" :scheduleds="times" />
            <!-- <p v-else class="text-primary-1 fs-6 mb-3">
              It is not possible to scheduled scheduleds on this day
            </p> -->
          </div>
          <p v-else class="text-primary-1 fs-6 mb-3">
            Loading schedules, please wait.
          </p>
          <div class="d-flex pb-4">
            <button :disabled="scheduleds.length === 0" class="
                btn
                text-white
                bl-shadow
                bg-primary-1 bg-hover-primary-1
                p-2
                px-5
                w-content
              " @click="
                () => {
                  changeStep(1);
                  changeSteps(1, 'add');
                }
              ">
              Next
            </button>
          </div>
        </div>
      </AccordionTab>
      <AccordionTab :disabled="true">
        <template #header>
          <div class="d-flex gap-3 align-items-center">
            <div class="circle d-flex">
              {{ currentSteps.includes(2) ? "✓" : "2" }}
            </div>
            <p>Choose teachers</p>
          </div>
        </template>
        <div class="content px-3 p-md-4" v-if="currentStep === 1">
          <p class="py-3">
            For each slot you've selected, you can now choose which available teacher you’d like to have classes with.
          </p>
          <div v-for="(scheduled, index) in scheduleds" :key="index"
            :class="availableTeachers.data[getAvailableTeachers[scheduled.id]]?.length === 0 ? 'd-none' : ''">
            <div class="d-flex gap-3 align-items-center">
              <div class="bg-primary-5 px-2 py-2 p-md-3 w-content border-r-10">
                {{
                    scheduled.formatDate
                }}
              </div>
              <i class="pi pi-trash text-danger fs-3 pointer"
                @click="deleteSchedule(scheduled.time_start, scheduled.date, scheduled.id)"></i>
            </div>
            <skeleton border-radius="10px" width="100%" height="15rem" class="skeleton-dark my-4"
              v-if="availableTeachers.isLoading" />
            <Carousel :key="scheduled.id" v-else :value="availableTeachers.data[getAvailableTeachers[scheduled.id]]"
              :numVisible="4" :numScroll="1" :responsiveOptions="responsiveOptions"
              contentClass="carousel-custom-2 px-2 mt-4 pb-3" indicatorsContentClass="d-none">
              <template #item="slotProps">
                <div class="px-3 my-4 pointer">
                  <card-small-info-component :name="slotProps.data?.name"
                    :total="availableTeachers.data[getAvailableTeachers[scheduled.id]].length"
                    :scheduledId="scheduled.id" :data="slotProps.data" :photo="slotProps.data.photo"
                    :lastName="slotProps.data.last_name"
                    :isTeacher="Object.entries(teachers).length > 0 ? (teachers[scheduled.id]?.id === slotProps.data.id) : false"
                    :id="slotProps.data.id" :clickscheduled="selectTeacher" :isClickable="true"
                    :cardTypes="['view-profile', 'w-last-name']" :goViewProfile="
                      () =>
                        toggleInfoTeacherModal(true, slotProps.data, 'class')
                    " />
                </div>
              </template>
            </Carousel>
          </div>

          <div class="d-flex flex-column flex-sm-row gap-3 pb-4">
            <button class="
                btn
                text-primary-1
                border-primary-1
                bg-hover-light-primary-1
                p-2
                px-5
                w-content
                w-100 w-sm-auto
              " @click="
                () => {
                  changeStep(0);
                  changeSteps(2, 'delete');
                }
              ">
              Back
            </button>
            <button :disabled="disableNextBtn" class="
                btn
                text-white
                bl-shadow
                bg-primary-1 bg-hover-primary-1
                p-2
                px-5
                w-content
                w-100 w-sm-auto
              " @click="
                () => {
                  changeStep(2);
                  changeSteps(2, 'add');
                }
              ">
              Next
            </button>

          </div>
        </div>
      </AccordionTab>
      <AccordionTab :disabled="true">
        <template #header>
          <div class="d-flex gap-3 align-items-center">
            <div class="circle d-flex">
              {{ currentSteps.includes(3) ? "✓" : "3" }}
            </div>
            <p>Confirm your class</p>
          </div>
        </template>
        <div class="content px-2 p-md-4 no-border" v-if="currentStep === 2">
          <p class="py-3">
            Please verify your selections and then click the confirmation button to finish booking. You can cancel your
            class up to 15 minutes before.
          </p>
          <div class="gap-4">
            <Carousel
              :value="scheduleds.filter(item => teachers[item.id]).sort((a, b) => {
                return new Date(a.date.replace(/-/g, '/') + ' ' + a.time_start) - new Date(b.date.replace(/-/g, '/') + ' ' + b.time_start)
              })"
              :numVisible="4" :numScroll="1" contentClass="carousel-custom-2 px-2 mt-4 pb-3"
              indicatorsContentClass="d-none" :responsiveOptions="responsiveOptions">
              <template #item="slotProps">
                <div class="px-3 my-4 d-none d-md-block">
                  <card-small-info-component :total="scheduleds.length === 1 ? 1 : 2"
                    :name="teachers[slotProps.data.id]?.name" :lastName="''" :photo="teachers[slotProps.data.id]?.photo"
                    :date="formatDate(slotProps.data.date + ' ' + slotProps.data.time_start, 'scheduled')"
                    :deleteText="'Delete class'" :cardTypes="['w-last-name', 'delete-card', 'w-date']"
                    :deleteCard="() => deleteClass(teachers[slotProps.data.id])" />
                </div>
                <div class="px-3 my-4 d-block d-md-none">
                  <card-small-info-component :total="scheduleds.length === 1 ? 2 : 2" :name="
                  teachers[slotProps.data.id]?.name" :lastName="''" :photo="teachers[slotProps.data.id]?.photo"
                    :date="formatDate(slotProps.data.date + ' ' + slotProps.data.time_start, 'scheduled')"
                    :deleteText="'Delete class'" :cardTypes="['w-last-name', 'delete-card', 'w-date']"
                    :deleteCard="() => deleteClass(teachers[slotProps.data.id])" />
                </div>
              </template>
            </Carousel>
          </div>

          <div class="d-flex flex-column flex-sm-row gap-3 pb-4">
            <button class="
                btn
                text-primary-1
                border-primary-1
                bg-hover-light-primary-1
                p-2
                px-5
                w-content
                w-100 w-sm-auto
              " @click="
                () => {
                  changeStep(1, 'time', true);
                  changeSteps(2, 'delete');
                }
              ">
              Back
            </button>
            <button :disabled="number_teachers === 0" class="
                btn
                text-white
                bl-shadow
                bg-primary-1 bg-hover-primary-1
                p-2
                px-5
                w-content
                w-100 w-sm-auto
              " @click="
  () => {
    parseDataUser();
    toggleClassBookedModal(true);
  }
              ">
              Confirm
            </button>

          </div>
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import CardSmallInfoComponent from "@/shared/components/Cards/CardSmallInfoComponent";
import CalendarCreateEventComponent from "@/shared/components/Calendar/CalendarCreateEventComponent";
import useScheduledClass from "@/shared/composables/ScheduledClass/useScheduledClass";
import useClasses from "@/modules/students/composables/MyClasses/useClasses";
import formatDate from "@/shared/utils/changeTexts/formatDate";
import useMyTeachers from "@/modules/students/composables/MyTeachers/useMyTeachers";
import moment from "moment";
import moment_timezone from "moment-timezone";
import { ref, onBeforeMount, computed, onUpdated, watch } from "vue";
import { useStore } from 'vuex'
export default {
  components: {
    CardSmallInfoComponent,
    CalendarCreateEventComponent,
  },
  setup() {

    const { toggleInfoTeacherModal } = useMyTeachers();
    const store = useStore()
    const responsiveOptions = [
      {
        breakpoint: '992px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },

    ]
    const {
      selectTime,
      scheduleds,
      teachersClass,
      availableTeachers,
      getAvailableTeachers,
      selectTeacher,
      teachers,
      deleteClass,
      number_teachers,
      parseDataUser,
      studentInformation,
      goToProfile,
      times,
      isLoadingTimes,
      teachersSelect
    } = useClasses();

    // const {  } = useScheduledClass()

    const teacherSchedule = computed(() => {
      return scheduleds.value?.map(item => {
        return availableTeachers.value.data[getAvailableTeachers.value[item.id]]
      }).filter(item => item?.length > 0)
    })

    const disableNextBtn = computed(() => {

      return teacherSchedule.value.length !== number_teachers.value || number_teachers.value === 0
    })

    const timezone = computed(() => {
      const initialState = moment(store.getters['shared/currentTimeTimezone'])
        .format('HH:mm')
      return initialState
    })

    // onBeforeMount(() => {
    //   setTimeout(() => {
    //     getActualTimeZone();
    //   }, 3000);
    // });

    onUpdated(() => {
      if (scheduleds.value) {
        console.log("asdasd")
        scheduleds.value.map(item => {
          const day = moment(new Date(store.getters['shared/currentTimeTimezone'])).day();
          let newDate = moment(`${item.date} ${item.time_start}`).day();
          item.formatDate = `${day === newDate ? "Today, " : ""} ${moment(`${item.date} ${item.time_start}`).format('MMMM Do YYYY, h:mm:ss a')}`
        })
      }
    })

    watch(teacherSchedule, (currentValue, oldValue) => {
      if (currentValue.length < oldValue.length &&
        number_teachers.value > currentValue.length
      ) number_teachers.value -= 1

    })

    const deleteSchedule = (start, date, id) => {
      availableTeachers.value.data[getAvailableTeachers.value[id]]
        .map(item => {
          if (teachers.value[id]?.id === item.id) {
            number_teachers.value -= 1
          }
        })

      selectTime(start, date, '', 'delete', id)

    }

    return {
      ...useScheduledClass(),
      goToProfile,
      teachersSelect,
      responsiveOptions,
      selectTime,
      studentInformation,
      isLoadingTimes,
      times,
      timezone,
      parseDataUser,
      deleteClass,
      selectTeacher,
      number_teachers,
      scheduleds,
      formatDate,
      teachers,
      teachersClass,
      toggleInfoTeacherModal,
      availableTeachers,
      getAvailableTeachers,
      disableNextBtn,
      deleteSchedule
    };
  },
};
</script>

<style lang="scss" scoped>
.cards>div {
  width: 100%;
  max-width: 250px;
}
</style>
