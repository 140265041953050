<template>
  <div class="bg-white py-5 px-2 p-md-5 mt-4 border-r-10">
    <Accordion v-model:activeIndex="currentStep" class="accordeon-custom accordeon-scheduled">
      <AccordionTab :disabled="true">
        <template #header>
          <div class="d-flex gap-3 align-items-center">
            <div class="circle d-flex">
              {{ currentSteps.includes(1) ? "✓" : "1" }}
            </div>
            <p>Choose teacher</p>
          </div>
        </template>
        <div class="content px-3 p-md-4" v-if="currentStep === 0">
          <div class="
              d-flex
              justify-content-betweeen
              align-items-center
              py-3
              w-100
            ">
            <p class="w-100">Choose a teacher to see their availability.</p>

            <div class="d-flex justify-content-end align-items-center gap-1 w-100">
              <skeleton width="100%" height="2rem" class="skeleton-dark" border-radius="10px"
                v-if="teachersAll.all.isLoading" />
              <div class="field-blue" v-else>
                <input-text v-model="filter_teacher" type="text" name="name" placeholder="Search by name..."
                  class="w-100" />
              </div>
              <i @click="searchTeachers" style="height: 45px" class="
                  pi pi-search
                  bg-primary-1
                  p-3
                  border-r-10
                  text-white
                  pointer
                "></i>
            </div>
          </div>
          <skeleton width="100%" height="15rem" class="skeleton-dark mb-4" border-radius="10px"
            v-if="teachersAll.all.isLoading" />
          <template v-else>
            <p v-if="teachersAll.all.data.length <= 0" class="text-primary-1 fs-6 mb-3">
              No available teachers were found
            </p>
            <Carousel v-else :value="teachersAll.all.data"
              :numVisible="3" :numScroll="1"
              contentClass="carousel-custom-2 px-md-2 mt-4 pb-3" indicatorsContentClass="d-none" :responsiveOptions="responsiveOptions">
              <template #item="slotProps">
                <div class="px-md-2 my-4">
                  <card-teacher-component 
                    :selectTeacher="selectByTeacher" 
                    :data="slotProps.data"
                    :isTeacher="teachersSelect.length > 0 ? (teachersSelect[0]?.teacher_id === slotProps.data.teacher_id) : false" 
                  />
                </div>
              </template>
            </Carousel>
          </template>
          <div class="d-flex pb-4">
            <button :disabled="number_teachers_select === 0" class="
                btn
                text-white
                bl-shadow
                bg-primary-1 bg-hover-primary-1
                p-2
                px-5
                w-content
              " @click="
                () => {
                  changeStep(1, option);
                  changeSteps(1, 'add');
                }
              ">
              Next
            </button>
          </div>
        </div>
      </AccordionTab>
      <AccordionTab :disabled="true">
        <template #header>
          <div class="d-flex gap-3 align-items-center">
            <div class="circle d-flex">
              {{ currentSteps.includes(2) ? "✓" : "2" }}
            </div>
            <p>Select time</p>
          </div>
        </template>
        <div class="content  px-3 p-md-4" v-if="currentStep === 1">
          <p class="py-3">
            All classes last 30 min. If you are going to scheduled more than one
            consecutive class, you will be scheduled in different Zoom links.
          </p>
          <skeleton v-if="availablescheduleds.isLoading" border-radius="10px" height="20rem" class="skeleton-dark mb-4"
            width="100%" />
          <div class="mb-3" v-else>

            <calendar-create-event-component type="teacher"
              :scheduleds="availablescheduleds.data" :selectTime="selectTeacherTime" />
<!-- 
            <p v-else class="text-primary-1 fs-6 mb-3">
              It is not possible to scheduled scheduleds on this day
            </p> -->
          </div>
          <div class="d-flex flex-column flex-sm-row gap-3 pb-4">
            <button class="
                btn
                text-primary-1
                border-primary-1
                bg-hover-light-primary-1
                p-2
                px-5
                w-content
                w-100 w-sm-auto
              " @click="
                () => {
                  changeStep(0, option, true);
                  changeSteps(2, 'delete');
                }
              ">
              Back
            </button>
            <button :disabled="scheduledsSelect.length === 0" class="
                btn
                text-white
                bl-shadow
                bg-primary-1 bg-hover-primary-1
                p-2
                px-5
                w-content
                w-100 w-sm-auto
              " @click="
                () => {
                  changeStep(2, option);
                  changeSteps(2, 'add');
                }
              ">
              Next
            </button>

          </div>
        </div>
      </AccordionTab>
      <AccordionTab :disabled="true">
        <template #header>
          <div class="d-flex gap-3 align-items-center">
            <div class="circle d-flex">
              {{ currentSteps.includes(3) ? "✓" : "3" }}
            </div>
            <p>Confirm your class</p>
          </div>
        </template>
        <div class="content px-3 p-md-4 no-border" v-if="currentStep === 2">
          <p class="py-3">
            Please verify your selections and then click the confirmation button to finish booking. You can cancel your class up to 15 minutes before.
          </p>
          <div class="gap-4 ">
            <Carousel :value="scheduledsSelect.sort((a,b)=>{
              return new Date(a.date.replace(/-/g, '/') + ' '+ a.time_start) - new Date(b.date.replace(/-/g, '/') + ' '+ b.time_start)})" 
              :numVisible="3" :numScroll="1"
              contentClass="carousel-custom-2 px-2 mt-4 pb-3" indicatorsContentClass="d-none" :responsiveOptions="responsiveOptions">
              <template #item="slotProps">
                <div class="px-3 my-4 d-none d-md-block">
                  <card-small-info-component
                    :total="scheduledsSelect.length === 1 ? 1 : 2"
                    :name="teachersSelect[0]?.name" :lastName="''" :photo="teachersSelect[0]?.photo"
                    :date="formatDate(slotProps.data.date + ' ' + slotProps.data.time_start, 'scheduled')"
                    :deleteText="'Delete class'" :cardTypes="['w-last-name', 'delete-card', 'w-date']"
                    :deleteCard="() => deleteClass(slotProps.data, 'byTeacher')" />
                </div>
                <div class="px-3 my-4 d-block d-md-none">
                  <card-small-info-component
                    :total="scheduledsSelect.length === 1 ? 2 : 2"
                    :name="teachersSelect[0]?.name" :lastName="''" :photo="teachersSelect[0]?.photo"
                    :date="formatDate(slotProps.data.date + ' ' + slotProps.data.time_start, 'scheduled')"
                    :deleteText="'Delete class'" :cardTypes="['w-last-name', 'delete-card', 'w-date']"
                    :deleteCard="() => deleteClass(slotProps.data, 'byTeacher')" />
                </div>

              </template>
            </Carousel>
          </div>
          <div class="d-flex flex-column  flex-sm-row gap-3 pb-4">
            <button class="
                btn
                text-primary-1
                border-primary-1
                bg-hover-light-primary-1
                p-2
                px-5
                w-content
                w-100 w-sm-auto
              " @click="
                () => {
                  changeStep(1, option, true);
                  changeSteps(2, 'delete');
                }
              ">
              Back
            </button>
            <button :disabled="scheduledsSelect.length === 0" class="
                btn
                text-white
                bl-shadow
                bg-primary-1 bg-hover-primary-1
                p-2
                px-5
                w-content
                w-100 w-sm-auto
              " @click="
                () => {
                  parseDataUser('teacher');
                  toggleClassBookedModal(true);
                }
              ">
              Confirm
            </button>
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import CardSmallInfoComponent from "@/shared/components/Cards/CardSmallInfoComponent";
import CardTeacherComponent from "@/shared/components/ScheduledClass/CardTeacherComponent";
import CalendarCreateEventComponent from "@/shared/components/Calendar/CalendarCreateEventComponent";
import useScheduledClass from "@/shared/composables/ScheduledClass/useScheduledClass";
import useClasses from "@/modules/students/composables/MyClasses/useClasses";
import formatDate from "@/shared/utils/changeTexts/formatDate";
import { useRoute } from 'vue-router';
import { onUpdated } from "vue";
export default {
  components: {
    CardSmallInfoComponent,
    CardTeacherComponent,
    CalendarCreateEventComponent,
  },
  props: {
    option: {
      type: String,
    },
  },
  setup(props) {
    const route = useRoute();
 const responsiveOptions =  [
			{
				breakpoint: '992px',
				numVisible: 2,
				numScroll: 2
			},
			{
				breakpoint: '768px',
				numVisible: 1,
				numScroll: 1
			},

		]
    const {
      number_teachers_select,
      selectTeacherTime,
      getAvailablescheduleds,
      availablescheduleds,
      scheduledsSelect,
      selectByTeacher,
      teachersSelect,
      deleteClass,
      parseDataUser,
    } = useClasses();

    const { currentStep, currentSteps, teachersAll, searchTeachers, changeStep, changeSteps, toggleClassBookedModal, filter_teacher } = useScheduledClass()
    if (Boolean(route.params.teacher) && route.params.teacher !== "null" && Object.entries(route.params).length > 0) {
      const teacher = JSON.parse(route.params.teacher);
      selectByTeacher(teacher);
      changeStep(1, props.option);
      changeSteps(1, 'add');
    }


    return {
      responsiveOptions,
      currentStep,
      currentSteps,
      teachersAll,
      filter_teacher,
      searchTeachers,
      changeStep,
      changeSteps,
      toggleClassBookedModal,
      number_teachers_select,
      selectTeacherTime,
      deleteClass,
      scheduledsSelect,
      teachersSelect,
      parseDataUser,
      selectByTeacher,
      formatDate,
      getAvailablescheduleds,
      availablescheduleds,
    };
  },
};
</script>

<style lang="scss" scoped>
.cards>div {
  width: 100%;
  max-width: 250px;
}
</style>
